
export default {

    rumus_Es(tbb, tbk) { //tbb & tbk
        let _tbb = parseFloat(tbb)
        let _tbk = parseFloat(tbk)
        let es =  ( ( 6.11 * Math.pow( 10, ((7.5 * _tbb)/(237.3 + _tbb)) ) ) - (0.7947 * (_tbk - _tbb)) )
        return es
    },

    rumus_E(tbk) { //tbk
        let _tbk = parseFloat(tbk)
        let e = ( 6.11 * Math.pow( 10 , (7.5 * _tbk/(237.3 + _tbk))) );
        return e
    },

    rumusWaktuSetempat(timezone, bujur) {
        if(!this.isValidValue(timezone)) {
            return ''
        }

        const boundary = {"7": 105, "8": 120, "9": 135}
        const selectedBound = boundary[String(timezone)]
        const diff = 4 * (selectedBound - bujur)
        const diff_rounded = Math.round(diff)

        return `${diff_rounded >=0?'+':''}${diff_rounded} menit`
        // return bujur <= selectedBound? `${diff_rounded} menit` : `${diff_rounded} menit`
    },

    parseIfOnlyNumber(val) {
        const onlyContainsNumbers = (str) => /^\d+$/.test(str);
        return onlyContainsNumbers(val) ? +val : val
    },

    parseIfOnlyNumberElseNull(val) {
        const onlyContainsNumbers = (str) => /^\d+$/.test(str);
        return onlyContainsNumbers(val) ? +val : null
    },

    getCleanValue(val) {
        // Avoid undefined and null, but not zero
        return val == "0"? val : (val || "")
    },

    isValidValue(val, invalid_special_value=[9999, "9999"]) {
        return val !== null && val !== undefined && 
            val !== "" && !isNaN(val) && !invalid_special_value.includes(val)
    },

    isValidTTAAiiString(str) {
        return typeof str === 'string' && /^[a-zA-Z]{4}\d{2}$/.test(str);
    },

    isValidCCCCString(str) {
        return typeof str === 'string' && /^[a-zA-Z]{4}$/.test(str);
    },

    isValidYYGGggString(str) {
        return typeof str === 'string' && /^\d{6}$/.test(str);
    },

    isValidDateYYGGgg(str) {
        if (!this.isValidYYGGggString(str)) return false;

        try {
            // Extract day, hour, and minute from the string
            const day = parseInt(str.slice(0, 2), 10); // "01" -> 1
            const hour = parseInt(str.slice(2, 4), 10); // "02" -> 2
            const minute = parseInt(str.slice(4, 6), 10); // "03" -> 3
            
            const nowUTC = new Date();

            // Create a UTC date (using a reference date, e.g., 2024-01-01)
            const utcDatatimestamp = new Date(Date.UTC(nowUTC.getUTCFullYear(), 
                nowUTC.getUTCMonth(),
                day, hour, minute));

            // Calculate the difference between the two times in milliseconds
            const timeDifferenceMs = nowUTC - utcDatatimestamp;

            // Convert milliseconds to minutes (divide by 1000 for seconds, 
            // then by 60 for minutes)
            const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);

            // Data terlalu cepat 1 jam - 26 harian

            // mengapa dibatasi 26 harian, karena potensi bugs
            // jika tanggal sekarang 31 jam 23.50 sedangkan tanggal data 1 jam 00
            // * Mungkin tidak perlu karena selisih positif
            // if (timeDifferenceMinutes <= -60 && timeDifferenceMinutes >= -60 * 24 * 26) {
            //     return false
            // }
            if (timeDifferenceMinutes <= -60) {
                return false
            }
            return true

        } catch (err) {
            return false
        }
    },

    // Spasi pada header GTS akan ditolak oleh inaswitching
	// Linux line endings (\n)
	removeLeadingTrailingSpaces(sandi) {
		try {
			// Remove all leading line breaks 
			sandi = sandi.replace(/^(?:[ \t]|\r\n|\n|\r)+/, '');

			// Split the string by any OS line endings
			// \r\n windows, \n linux, \r mac os versi 9 kebawah
			const lines = sandi.split(/\r\n|\n|\r/);
			
			// Trim the first element
			if (lines.length > 0) {
				lines[0] = lines[0].trim();
			}
			
			// Join the lines back with the appropriate line ending
			let result = lines.join('\n');

			// Remove all trailing line breaks and spaces
    		result = result.replace(/(?:[ \t]|\r\n|\n|\r)+$/, '');

			return result;
		} catch(error) {
			console.error('Cannot remove leading and trailing spaces', error)
			return null
		}
	},
}